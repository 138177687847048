import { triggerTurboStream } from '../utils/trigger_turbo_stream';
import { BaseIntersectionController } from '../utils/base_intersection_controller';

export default class extends BaseIntersectionController {
  elementIntersected(entry: IntersectionObserverEntry) {
    this.fetchMore(entry.target);
  }

  onFetchMoreClick(event: Event) {
    this.fetchMore(event.currentTarget as Element);
  }

  fetchMore(element: Element) {
    const page = element.getAttribute('data-page')!;
    const url = new URL(document.location.href);
    url.searchParams.set('page', page);
    void triggerTurboStream({ src: url }).then(() => element.remove());
  }
}
